import { configureStore } from '@reduxjs/toolkit';
import counterReducer from 'components/SessionStatus/SessionStoreSetter';
import { adminWriteSlice, emailsValuePropsSlice, externalAccountsSlice, pendingAuthorizationInfo, pendingReviewsSlice, scopesSlice, sessionListParamsSlice, userGroupsSlice, userSlice, visibleAccountsSlice } from 'lib/redux/store';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    sessionList: sessionListParamsSlice.reducer,
    userGroupInfo: userGroupsSlice.reducer,
    externalAccounts: externalAccountsSlice.reducer,
    scope: scopesSlice.reducer,
    adminWrite: adminWriteSlice.reducer,
    user: userSlice.reducer,
    visibleAccounts: visibleAccountsSlice.reducer,
    pendingReviews: pendingReviewsSlice.reducer,
    authenticationInfo: pendingAuthorizationInfo.reducer,
    emailValueProps: emailsValuePropsSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;