import { Loader } from 'components/Loader';
import { reloadAuthorizationInfoInitialState } from 'lib/redux/store';
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AnyAction } from 'redux';
import { AuthorizationInfo } from './utils';
import { RootState } from 'store';
import { connect } from 'react-redux';

// Define the types for the props
interface PrivateRouteProps {
    authentication_info: AuthorizationInfo | null;
    dispatch: Dispatch<AnyAction>;
    component: React.ComponentType<any>;
}

const PrivateRouteImpl: React.FC<PrivateRouteProps> = ({ authentication_info, dispatch, component: Component, ...rest }) => {
    if (!authentication_info) {
        reloadAuthorizationInfoInitialState(dispatch);
        return <Loader />;
    }

    if (authentication_info.apiKey == null) {
        return <Navigate to={'/login'}/>
    }

    return <Component {...rest} />;
};

const mapStateToProps = (state: RootState) => {
    return {
        authentication_info: state.authenticationInfo.value,
    };
  };
  
const ReduxWrapped = connect(mapStateToProps)(PrivateRouteImpl)

export { ReduxWrapped as PrivateRoute}