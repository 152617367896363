export enum ATTRIBUTION_CHANNEL {
    COLD_EMAIL ='COLD_EMAIL',
    LINKEDIN_DM = 'LINKEDIN_DM', 
    LINKEDIN_POST = 'LINKEDIN_POST', 
    TRELLUS_WEBSITE = 'TRELLUS_WEBSITE',
    MANUAL = 'MANUAL',
    CALLBLITZ = 'CALLBLITZ',
    DOM = 'DOM'
  }

export enum TRACKER_TYPE {
    INSTALL = 'INSTALL',
    CLICK = 'CLICK',
    UNINSTALL = 'UNINSTALL'
}

export const ATTRIBUTION_CHANNEL_STORAGE_KEY = 'attribution_channel' // used in local storage as well as the param to send to backend
export const REFERRER_STORAGE_KEY = 'referrer' // used in local storage as well as the param to send to backend
export const REFERRER_URL_KEY = 'ref' // used in the url header to store the referral e.g., ?ref='<X>'. 
export const LEARN_LINK = 'https://app.trellus.ai/learn'
export const INSTALL_LINK = 'https://app.trellus.ai/install'
export const UNINSTALL_FORM = 'https://form.typeform.com/to/fCoRsTkq'


export enum DIALERS {
  SALESLOFT= 'SALESLOFT',
  OUTREACH='OUTREACH', // outreach iframe either on extension or web. 1 of 4 iframes. filtered for dialer later.
  OUTREACH_RINGCENTRAL= 'OUTREACH_RINGCENTRAL', // ringcentral dialer on outreach...
  ORUM='ORUM',
  HUBSPOT='HUBSPOT',
  APOLLO='APOLLO',
  PHONEBURNER='PHONEBURNER',
  SALESFINITY='SALESFINITY',
  CONNECT_AND_SELL='CONNECT_AND_SELL',
  MONSTER_CONNECT='MONSTER_CONNECT',
  ALOWARE='ALOWARE',
  AIRCALL='AIRCALL',
  OUTPLAY='OUTPLAY',
  KONCERT='KONCERT',
  ONEPGR='ONEPGR',
  ZOOMINFO='ZOOMINFO',
  NOOKS='NOOKS',
  CONVOSO='CONVOSO',
  FRONTSPIN='FRONTSPIN',
  SELLX='SELLX',
  FRESHWORKS='FRESHWORKS',
  EIGHT_BY_EIGHT='EIGHT_BY_EIGHT',
  CALLRAIL='CALLRAIL',
  ZOHO_WILDIX='ZOHO',
  SALESFORCE='SALESFORCE',
  DIALPAD='DIALPAD',
  OPENPHONE='OPENPHONE',
  VICIDIAL='VICIDIAL',
  ASPIRED_BUSINESS_COACH='ASPIRED_BUSINESS_COACH',
  }

  export const DIALERS_TO_HUMAN_READABLE: {[k in DIALERS]: string} = {
    [DIALERS.SALESLOFT]: 'Salesloft',
    [DIALERS.OUTREACH]: "Outreach",
    [DIALERS.OUTREACH_RINGCENTRAL]: "Outreach",
    [DIALERS.ORUM]: "Orum",
    [DIALERS.HUBSPOT]: "Hubspot",
    [DIALERS.APOLLO]: "Apollo",
    [DIALERS.PHONEBURNER]: "Phoneburner",
    [DIALERS.SALESFINITY]: "Salesfinity",
    [DIALERS.CONNECT_AND_SELL]: "Connect and Sell",
    [DIALERS.MONSTER_CONNECT]: "Monster Connect",
    [DIALERS.ALOWARE]: "Aloware",
    [DIALERS.AIRCALL]: "Aircall",
    [DIALERS.OUTPLAY]: "Outplay",
    [DIALERS.KONCERT]: "Koncert",
    [DIALERS.ONEPGR]: "OnePgr",
    [DIALERS.ZOOMINFO]: "Engage",
    [DIALERS.NOOKS]: "Nooks",
    [DIALERS.CONVOSO]: "Convoso",
    [DIALERS.FRONTSPIN]: "FrontSpin",
    [DIALERS.SELLX]: "SellX",
    [DIALERS.FRESHWORKS]: "Freshworks",
    [DIALERS.EIGHT_BY_EIGHT]: "8x8",
    [DIALERS.CALLRAIL]: "CallRail",
    [DIALERS.ZOHO_WILDIX]: "Zoho",
    [DIALERS.SALESFORCE]: "Salesforce",
    [DIALERS.DIALPAD]: "Dialpad",
    [DIALERS.OPENPHONE]: "OpenPhone",
    [DIALERS.VICIDIAL]: "ViciDial",
    [DIALERS.ASPIRED_BUSINESS_COACH]: "Aspired"
  }
