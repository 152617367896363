import { MetricDefinition, MetricFormat, MetricType, OVERALL_SCORE_TYPE } from "cfg/score";
import { Typography } from "interfaces/typography";
import React from "react";

type DebouncerScoreInfoProps = {
    title: string
    content: JSX.Element
    customStyle?: React.CSSProperties
    onHoverTextInfo?: string
}

type DebouncerScoreInfoState = {
    hovered: boolean
}

export class DebouncerScoreInfo extends React.Component<DebouncerScoreInfoProps, DebouncerScoreInfoState> {
    constructor(props: DebouncerScoreInfoProps) {
        super(props)
        this.state = {hovered: false}
    }

    render() {
        return (
        <div 
        onMouseEnter={() => this.setState({hovered: true})}
        onMouseLeave={() => this.setState({hovered: false})}
        className="flex flex-col">
            <div className="flex flex-row gap-1 items-center p-1"
            style={{
                cursor: this.props.onHoverTextInfo ? 'pointer' : 'default',
                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                borderRadius: '8px',
                ...(this.props.customStyle ?? {})        
            }}
            >
                <Typography fontWeight={550}>
                    {`${this.props.title}:`}
                </Typography>
                {this.props.content}
            </div>
            {this.props.onHoverTextInfo ? <div className="relative" style={{display: this.state.hovered ? 'flex' : 'flex'}} >
                <div style={{
                    display: this.state.hovered ? 'flex' : 'none',
                    position: 'absolute',
                    zIndex: 100,
                    backgroundColor: 'white',
                    padding: '8px',
                    borderRadius: '8px',
                    minWidth: '150px',
                    maxWidth: '200px',
                    width: 'fit-content',
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                }}>
                    <Typography variant="largeParagraph">
                        {this.props.onHoverTextInfo}
                    </Typography>
                </div>
            </div> : null}
        </div>)
    }

}

type ScoreProps = {
    score: MetricFormat
}
    

export class Score extends React.Component<ScoreProps> {
    constructor(props: ScoreProps) {
        super(props)
    }

    renderScoreContent() {
        return (
        <Typography>
            {`${this.props.score.overall_score ?? 0}${this.props.score.overall_score_type === OVERALL_SCORE_TYPE.PERCENTAGE ? "%" : ""}`}
        </Typography>
        )
    }

    _getScoreColor() {
        if (this.props.score.overall_score == null) return 'lightgray'
        const number = parseInt(this.props.score.overall_score.toString())
        if (number < 25) return '#ff000052'
        if (number < 40) return '#efff0052'
        if (number < 60) return '#7fcb6652'
        return '#55b5369c'
    }

    renderSuccessStatus(v: boolean) {
        return <div 
        className={("w-3 h-3 opacity-75")} 
        style={{'borderRadius': '50%', 'backgroundColor': v ? 'green' : 'red'}}>
        </div>
    }

    renderBox(title: string, content: JSX.Element, customBgColor?: string, onHoverTextInfo?: string) {
        return <DebouncerScoreInfo title={title} content={content} customStyle={{backgroundColor: customBgColor}} onHoverTextInfo={onHoverTextInfo}/>
    }

    renderMetricContent(m: MetricDefinition) {
        switch (m.metric_type) {
            case MetricType.SCORE:
                return <Typography>{`${m.value}/${m.max_value}`}</Typography>
            case MetricType.SUCCESS:
                return this.renderSuccessStatus(Boolean(m.value))
            default:
                return <Typography>{m.value}</Typography>
        }
    }

    renderMetricInfo(m: MetricDefinition) {
        const content = this.renderMetricContent(m)
        return this.renderBox(m.metric_name, content, undefined, m.text)
    }

    render() {
        if (!this.props.score.score_is_applicable) return null
        return (<div className='flex flex-row items-center gap-1 w-full flex-grow flex-wrap'> 
            {this.renderBox(this.props.score.overall_score_name ?? 'Score', this.renderScoreContent(), this.props.score.overall_score_type === OVERALL_SCORE_TYPE.PERCENTAGE ? this._getScoreColor() : undefined, this.props.score.overall_explanation ?? undefined)}
            {this.props.score.metrics.map((m: MetricDefinition) => this.renderMetricInfo(m))}
        </div>)
    }
}

