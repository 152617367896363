import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { MICRO_TO_MS, MS_TO_MICRO } from "cfg/const";
import { Loader } from "components/Loader";
import { SessionDetails } from "components/SessionDetails";
import { Table, TableProps } from "components/Table";
import { augmentRepSummary } from "components/Widgets/PreviousCall";
import { UserDataResult, PreviousCallSummary, PreviousCallSummaryResult, VisibleAccountsResult } from "interfaces/services";
import { Typography } from "interfaces/typography";
import { convertFromReduxSafeVisibleAccounts, reloadVisibleAccounts } from "lib/redux/store";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "store";
import { durationToString, shortenedDateToString } from "time";


export type SummarizedCallHistoryPropsDetails = {
    'selectedSessionId'?: string
    'updatedSelectedSessionId': (x: string) => void,
    'previousCallSummaries': PreviousCallSummaryResult | null
    'pageClosed': boolean,
    'isSmall'?: boolean
    'noDecorations'?: boolean
}

export type SummarizedCallHistoryProps = SummarizedCallHistoryPropsDetails & {
    'visibleAccounts': VisibleAccountsResult | null,
    dispatch: Dispatch<AnyAction>
}

export class SummarizedCallHistoryImpl extends React.Component<SummarizedCallHistoryProps, {}> {
    constructor(props: SummarizedCallHistoryProps) { super(props) }

    componentDidMount(): void {
        if (!this.props.visibleAccounts) reloadVisibleAccounts(this.props.dispatch)
    }

    _getSummaries(callInfo: PreviousCallSummary): JSX.Element {
        let repSummary = callInfo.repSummary
        if (!repSummary && !callInfo.prospectSummary) repSummary = augmentRepSummary(callInfo, false, true)
        return (<div className="w-full flex flex-col gap-1 flex-start text-left">
            <Typography variant="mediumParagraph">
                {((repSummary ?? '') + (repSummary ? '.' : '')) + 
                (repSummary && callInfo.prospectSummary ? ' ' : '') + 
                ((callInfo.prospectSummary ?? '') + (callInfo.prospectSummary ? '.' : ''))}
            </Typography>
            {this.props.visibleAccounts && this.props.visibleAccounts.users.length > 1 ? this._convertProspectUserNameField(callInfo) : null}
            </div>)
    }

    _getDurationCol(callInfo: PreviousCallSummary): JSX.Element {
        return <Typography variant="mediumParagraph">
            {durationToString(callInfo.duration*MS_TO_MICRO)}
        </Typography>
    }

    _getCurrentShownItemIdx(): number {
        return this.props.selectedSessionId && this.props.previousCallSummaries ? this.props.previousCallSummaries.findIndex((v) => v.sessionId === this.props.selectedSessionId) : -1
    }

    _convertUserIdToName(userId: string) { 
        const user = this.props.visibleAccounts?.users?.find((value: UserDataResult) => value.user_id === userId)
        return user ? user.user_name : 'Unkown User' 
    }

  _convertProspectUserNameField(item: PreviousCallSummary): JSX.Element { 
     return (
     <div className='items-center w-fit flex flex-row justify-start text-left gap-1' style={{'opacity': (item.sessionMetric && (item.sessionMetric?.targetDuration > 0 || item.sessionMetric?.gatekeeperDuration > 0)) ? '0.5': '1'}}>
      <div className='flex flex-col'>
        <Typography variant='largeParagraph' color='#000000' style={{'textAlign': 'left', 'fontSize': '11px', 'textTransform': 'none'}}>
          {this._convertUserIdToName(item.userId)}
        </Typography>
      </div>
    </div>)
   }

    _getMainViewTableProps(): TableProps<PreviousCallSummary> {
        const tableProps: TableProps<PreviousCallSummary> = {
        keyFunc: (item: PreviousCallSummary): string => item.sessionId,
        columnFuncs: [
          (item: PreviousCallSummary) => this._getSummaries(item),
          (item: PreviousCallSummary) => this._getDurationCol(item),
        ],
        trClassStyleRowFn: (item: PreviousCallSummary) => {return (item.sessionMetric && (item.sessionMetric?.targetDuration > 0 || item.sessionMetric?.gatekeeperDuration > 0)) ? {'opacity': '1'} : {'opacity': '0.5'}}, 
        items: this.props.previousCallSummaries ?? [],
        onRowClick: (item: PreviousCallSummary, idx: number, col_idx?: number, classList?: DOMTokenList): void =>  {
            if (this.props.isSmall) window.open(`https://app.trellus.ai/transcripts?id=${item.sessionId}`)
            else this.props.updatedSelectedSessionId(item.sessionId)
        },
        highlightedItemIdx: this._getCurrentShownItemIdx(),
        tableWidthFull: true,
        trClassStyleHighlighted: {'backgroundColor': '#e5e3e3'},
        trClassStyleNonHighlighted: {},
        group_by: (item: PreviousCallSummary) => shortenedDateToString(new Date(item.scheduledStart*MICRO_TO_MS))}
        return tableProps
      }
    
    render() {
        const style = this.props.noDecorations ? {} : {   'maxWidth': '300px', 'backgroundColor': 'white',  'border': '0.8px solid rgba(0, 0, 0, 0.2)', 'boxShadow': '0px 0px 4px rgba(0, 0, 0, 0.35)', 'borderRadius': '10px'}
        return (
            <div className="w-full h-full flex flex-row gap-1" style={{'maxHeight': 'calc(100vh - 70px)'}}>
                <div className={'w-full h-full flex-auto min-h-0 overflow-y-hidden overflow-x-hidden'} 
                style={{
                 }}>
                    <div tabIndex={0} className='overflow-y-scroll w-full h-full flex-auto min-h-0 flex flex-col items-end mr-1 bg-white' style={{outline: 'none', 'paddingLeft': "4%", 'paddingRight': "4%"}}>
                        {this.props.previousCallSummaries ? this.props.previousCallSummaries.length === 0 ? 
                        <div className='w-full h-4/5 text-center items-center flex justify-center'>
                            <Typography variant='caption'>
                                No transcripts found
                            </Typography>
                        </div> : <Table {...this._getMainViewTableProps()}></Table> : <Loader />}
                    </div> 
                </div>
                {this.props.selectedSessionId ?<div className='w-full' style={{'height': '100%'}}>
                    <SessionDetails pageClosed={this.props.pageClosed} minimalView={true} viewerUserId={undefined} isInView={true} sessionId={this.props.selectedSessionId} coachingView={false}/>
                </div> : null}
            </div>)
            }
}

const ReduxWrapped = connect((state: RootState) => {
    return {
      visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts, (v) => v.team_is_active && v.can_dial),
    }
})(SummarizedCallHistoryImpl)
export { ReduxWrapped as SummarizedCallHistory}
